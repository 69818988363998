import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import styles from "./Feed.module.css";
import NewsSkeletonLoader from "components/attendee/news-skeleton-loader/NewsSkeletonLoader";

const Feed = ({ selectedSection }) => {
  const [news, setNews] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("News");

  const fetchNews = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "http://localhost:10000/api/v1/news/get-all-news",
        {
          withCredentials: false,
        },
      );

      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response.data, "text/xml");
      const items = xmlDoc.getElementsByTagName("item");

      const newsArray = Array.from(items).map((item, index) => {
        const pubDate = new Date(
          item.getElementsByTagName("pubDate")[0].textContent,
        );
        const formattedDate = formatDate(pubDate);
        const categories = Array.from(
          item.getElementsByTagName("category"),
        ).map((cat) => cat.textContent);

        return {
          id: `${index}-${item.getElementsByTagName("guid")[0].textContent}`,
          guid: item.getElementsByTagName("guid")[0].textContent,
          title: item.getElementsByTagName("title")[0].textContent,
          link: item.getElementsByTagName("link")[0].textContent,
          creator: item.getElementsByTagName("dc:creator")[0].textContent,
          category: categories[0],
          categories: categories,
          description: item.getElementsByTagName("description")[0].textContent,
          pubDate: formattedDate,
          rawDate: pubDate,
          enclosure: item
            .getElementsByTagName("enclosure")[0]
            .getAttribute("url"),
        };
      });

      newsArray.sort((a, b) => b.rawDate - a.rawDate);
      setNews(newsArray);
    } catch (error) {
      console.error("Error fetching news:", error);
      setError("Failed to fetch news. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (selectedSection === "News") {
      fetchNews();
    }
  }, [selectedSection, fetchNews]);

  const formatDate = (date) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const setCategory = (category) => {
    setSelectedCategory(category);
  };

  const filteredNews = news.filter(
    (item) =>
      item.category === selectedCategory ||
      item.categories.includes(selectedCategory),
  );

  if (error) {
    return <div className={styles.errorMessage}>{error}</div>;
  }

  if (loading) {
    return <NewsSkeletonLoader />;
  }

  return (
    <div className={styles.newsFeed}>
      <div className={styles.categoryFilter}>
        {["News", "Opinion", "Israel-Palestine Conflict"].map((category) => (
          <button
            key={category}
            className={`${styles.categoryButton} ${
              selectedCategory === category ? styles.selected : ""
            }`}
            onClick={() => setCategory(category)}
          >
            {category}
          </button>
        ))}
      </div>
      {filteredNews.length > 0 && (
        <div className={styles.featuredNews}>
          <img
            src={
              filteredNews[0].enclosure || "https://via.placeholder.com/800x400"
            }
            alt={filteredNews[0].title}
            className={styles.featuredImage}
          />
          <div className={styles.featuredContent}>
            <a
              href={filteredNews[0].link}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.featuredTitleLink}
            >
              <h2 className={styles.featuredTitle}>{filteredNews[0].title}</h2>
            </a>
            <p className={styles.featuredDescription}>
              {filteredNews[0].description}
            </p>
            <p className={styles.featuredCreator}>
              By {filteredNews[0].creator}
            </p>
          </div>
        </div>
      )}
      <div className={styles.liveUpdates}>
        <h3 className={styles.liveUpdatesTitle}>LIVE UPDATES</h3>
        <ul className={styles.updatesList}>
          {filteredNews.slice(1, 4).map((item) => (
            <li key={item.id} className={styles.updateItem}>
              <span className={styles.updateTimestamp}>{item.pubDate}</span>
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.updateLink}
              >
                <p className={styles.updateText}>{item.title}</p>
              </a>
              <p className={styles.updateCreator}>By {item.creator}</p>
            </li>
          ))}
        </ul>
      </div>
      <ul className={styles.newsList}>
        {filteredNews.slice(4).map((item) => (
          <li key={item.id} className={styles.newsItem}>
            <div className={styles.mediaContainer}>
              <img
                src={item.enclosure || "https://via.placeholder.com/150x100"}
                alt={item.title}
                className={styles.newsImage}
              />
            </div>
            <div className={styles.newsContent}>
              <h3 className={styles.newsTitle}>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  {item.title}
                </a>
              </h3>
              <span className={styles.newsTimestamp}>{item.pubDate}</span>
              <p className={styles.newsDescription}>{item.description}</p>
              <p className={styles.newsAuthor}>By {item.creator}</p>
              <div className={styles.newsCategories}>
                {item.categories.map((cat, index) => (
                  <span key={index} className={styles.category}>
                    {cat}
                  </span>
                ))}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Feed;
