import React from "react";
import styles from "./Card.module.css";

const Card = ({
  icon,
  title,
  onClick,
  className,
  iconWrapperClassName,
  titleClassName,
  children,
}) => {
  return (
    <div className={`${styles.card} ${className}`} onClick={onClick}>
      <div className={`${styles.iconWrapper} ${iconWrapperClassName}`}>
        <img src={icon} alt={title} className={styles.cardIcon} />
      </div>
      <h3 className={`${styles.cardTitle} ${titleClassName}`}>{title}</h3>
      {children}
    </div>
  );
};

export default Card;
