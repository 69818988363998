import React from "react";
import styles from "./Services.module.css";
import ServiceItem from "../../../../components/attendee/service-item/ServiceItem";
import marriageIcon from "assets/services/marriage_icon.png";
import graveyardIcon from "assets/services/graveyard_icon.png";
import quranIcon from "assets/services/quran_icon.png";
import madrasaIcon from "assets/services/madrasa_icon.png";
import counsellingIcon from "assets/services/islamic_counselling_icon.png";
import libraryIcon from "assets/services/library_icon.png";
import healthIcon from "assets/services/health_icon.png";
import languageIcon from "assets/services/language_icon.png";
import askImaamIcon from "assets/services/ask_imaam_icon.png";

const Services = () => {
  const services = [
    { name: "Marriage", icon: marriageIcon, path: "marriage" },
    { name: "Janazah", icon: graveyardIcon, path: "janazah" },
    { name: "Qur'an", icon: quranIcon, path: "tahfizul-quran" },
    { name: "Madrasah", icon: madrasaIcon, path: "madrasah" },
    { name: "Counselling", icon: counsellingIcon, path: "counselling" },
    { name: "Library", icon: libraryIcon, path: "library" },
    { name: "Health", icon: healthIcon, path: "health" },
    { name: "Language", icon: languageIcon, path: "language" },
    { name: "Ask Imaam", icon: askImaamIcon, path: "ask-imaam" },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.servicesGrid}>
        {services.map((service, index) => (
          <ServiceItem key={index} service={service} />
        ))}
      </div>
    </div>
  );
};

export default Services;
