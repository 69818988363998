import React from "react";

const Janazah = () => {
  return (
    <div>
      <h1>Janazah Services</h1>
      <p>
        Welcome to our Marriage Services page. Here you can find information
        about our marriage-related offerings.
      </p>
      {/* Add more content specific to marriage services */}
    </div>
  );
};

export default Janazah;
