import React from "react";
import styles from "./Body.module.css";

function Body() {
  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>
            Empower the Umma
            <br /> through your Masjid
          </h1>
        </div>

        {/* <p className={styles.paragraph}>
         Unlock the power of fully funded Masjid .
        </p> */}
        <div className={styles.buttonContainer}>
          <a
            href="https://calendly.com/admin-rsyl/introduction-to-ummapay"
            className={styles.button}
          >
            Join Now
          </a>
        </div>
      </div>
    </div>
  );
}

export default Body;
