import React from "react";
import { Link, useLocation } from "react-router-dom";
import { IoHomeOutline, IoCalendarClearOutline } from "react-icons/io5";
import { HiOutlineNewspaper } from "react-icons/hi2";
import { MdOutlineSubscriptions } from "react-icons/md";
import { TbGridDots } from "react-icons/tb";
import styles from "./Navigation.module.css";

const Navigation = () => {
  const location = useLocation();

  const navItems = [
    { to: "/dashboard/attendee", icon: IoHomeOutline, label: "Home" },
    {
      to: "/dashboard/attendee/services",
      icon: TbGridDots,
      label: "Services",
    },
    {
      to: "/dashboard/attendee/news",
      icon: HiOutlineNewspaper,
      label: "News",
    },
    {
      to: "/dashboard/attendee/events",
      icon: IoCalendarClearOutline,
      label: "Events",
    },
    {
      to: "/dashboard/attendee/subscription",
      icon: MdOutlineSubscriptions,
      label: "Subscription",
    },
  ];

  return (
    <>
      <nav className={styles.desktopNav}>
        <Link to="/dashboard/attendee" className={styles.logoContainer}>
          <img src="/favicon.ico" alt="Logo" className={styles.logo} />
        </Link>
        <ul className={styles.desktopMenuItems}>
          {navItems.map(({ to, icon: Icon, label }) => (
            <li key={to}>
              <Link
                to={to}
                className={`${styles.navLink} ${location.pathname === to ? styles.active : ""}`}
              >
                <Icon className={styles.icon} />
                <span className={styles.label}>{label}</span>
              </Link>
            </li>
          ))}
        </ul>
        <div className={styles.userIcon}>B</div>
      </nav>
      <nav className={styles.mobileNav}>
        <ul className={styles.mobileMenuItems}>
          {navItems.map(({ to, icon: Icon, label }) => (
            <li key={to}>
              <Link
                to={to}
                className={`${styles.navLink} ${location.pathname === to ? styles.active : ""}`}
              >
                <Icon className={styles.icon} />
                <span className={styles.label}>{label}</span>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default Navigation;
