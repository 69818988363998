import React, { useState } from "react";
import {
  HiOutlineBell,
  HiOutlineMoon,
  HiOutlineLanguage,
  HiOutlineQuestionMarkCircle,
  HiOutlineArrowRightOnRectangle,
} from "react-icons/hi2";
import styles from "./Settings.module.css";
import BackButton from "components/ui/back-button/BackButton";

const Settings = () => {
  const [notifications, setNotifications] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [language] = useState("English");

  const toggleNotifications = () => {
    setNotifications(!notifications);
    // TODO: Implement notification toggle logic
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    // TODO: Implement dark mode toggle logic
  };

  const changeLanguage = () => {
    // TODO: Implement language change logic
    alert("Language change functionality to be implemented");
  };

  const openHelp = () => {
    // TODO: Implement help/support logic
    alert("Help and support functionality to be implemented");
  };

  const handleLogout = () => {
    // TODO: Implement logout logic
    alert("Logout functionality to be implemented");
  };

  return (
    <div className={styles.container}>
      <BackButton />
      <div className={styles.settingsList}>
        <div className={styles.settingItem}>
          <div className={styles.settingInfo}>
            <HiOutlineBell className={styles.icon} />
            <span>Notifications</span>
          </div>
          <label className={styles.switch}>
            <input
              type="checkbox"
              checked={notifications}
              onChange={toggleNotifications}
            />
            <span className={styles.slider}></span>
          </label>
        </div>
        <div className={styles.settingItem}>
          <div className={styles.settingInfo}>
            <HiOutlineMoon className={styles.icon} />
            <span>Dark Mode</span>
          </div>
          <label className={styles.switch}>
            <input
              type="checkbox"
              checked={darkMode}
              onChange={toggleDarkMode}
            />
            <span className={styles.slider}></span>
          </label>
        </div>
        <div className={styles.settingItem} onClick={changeLanguage}>
          <div className={styles.settingInfo}>
            <HiOutlineLanguage className={styles.icon} />
            <span>Language</span>
          </div>
          <span>{language}</span>
        </div>
        <div className={styles.settingItem} onClick={openHelp}>
          <div className={styles.settingInfo}>
            <HiOutlineQuestionMarkCircle className={styles.icon} />
            <span>Help & Support</span>
          </div>
        </div>
        <div className={styles.settingItem} onClick={handleLogout}>
          <div className={styles.settingInfo}>
            <HiOutlineArrowRightOnRectangle className={styles.icon} />
            <span>Logout</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
