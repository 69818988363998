import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { saveNikahDetails } from "store/nikahSlice";
import Form, { FormGroup, Label, Input } from "components/ui/form/Form";
import DatePicker from "components/ui/date-picker/DatePicker";
import Popup from "components/ui/popup/Popup";
import { IoClose } from "react-icons/io5";
import styles from "./Nikah.module.css";

const Nikah = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    groomName: "",
    brideName: "",
    bookingDate: null,
    bookingTime: "",
    venue: "",
    witnesses: "",
    additionalNotes: "",
  });
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showTimePopup, setShowTimePopup] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevState) => ({
      ...prevState,
      bookingDate: date,
    }));
    setShowDatePicker(false);
    setShowTimePopup(true);
  };

  const handleTimeSelect = (time) => {
    setFormData((prevState) => ({
      ...prevState,
      bookingTime: time,
    }));
    setShowTimePopup(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(saveNikahDetails(formData));
    // Add any additional logic here (e.g., API calls, navigation)
  };

  const toggleDatePicker = (e) => {
    e.preventDefault();
    setShowDatePicker(!showDatePicker);
  };

  const clearBooking = (e) => {
    e.preventDefault();
    setFormData((prevState) => ({
      ...prevState,
      bookingDate: null,
      bookingTime: "",
    }));
  };

  const formatDate = (date) => {
    if (!date) return "";
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString(undefined, options);
  };

  const timeSlots = [
    "9:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "1:00 PM",
    "2:00 PM",
    "3:00 PM",
    "4:00 PM",
  ];

  return (
    <Form
      onSubmit={handleSubmit}
      title="Nikah Registration"
      submitButtonText="Submit Registration"
    >
      <FormGroup>
        <Label htmlFor="groomName">Groom's Name</Label>
        <Input
          type="text"
          id="groomName"
          name="groomName"
          value={formData.groomName}
          onChange={handleInputChange}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="brideName">Bride's Name</Label>
        <Input
          type="text"
          id="brideName"
          name="brideName"
          value={formData.brideName}
          onChange={handleInputChange}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label>Book Your Slot</Label>
        {formData.bookingDate && formData.bookingTime ? (
          <div className={styles.bookedSlot}>
            <span>{`${formatDate(formData.bookingDate)} at ${formData.bookingTime}`}</span>
            <button onClick={clearBooking} className={styles.clearButton}>
              <IoClose />
            </button>
          </div>
        ) : (
          <button onClick={toggleDatePicker} className={styles.bookSlotButton}>
            Select Date
          </button>
        )}
        {showDatePicker && (
          <DatePicker
            selectedDate={formData.bookingDate}
            onChange={handleDateChange}
          />
        )}
        <Popup isOpen={showTimePopup} onClose={() => setShowTimePopup(false)}>
          <h3>Select a Time Slot</h3>
          <div className={styles.timeSlotGrid}>
            {timeSlots.map((slot) => (
              <button
                key={slot}
                className={styles.timeSlotButton}
                onClick={() => handleTimeSelect(slot)}
              >
                {slot}
              </button>
            ))}
          </div>
        </Popup>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="venue">Venue</Label>
        <Input
          type="text"
          id="venue"
          name="venue"
          value={formData.venue}
          onChange={handleInputChange}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="witnesses">Witnesses (comma-separated)</Label>
        <Input
          type="text"
          id="witnesses"
          name="witnesses"
          value={formData.witnesses}
          onChange={handleInputChange}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="additionalNotes">Additional Notes</Label>
        <Input
          as="textarea"
          id="additionalNotes"
          name="additionalNotes"
          value={formData.additionalNotes}
          onChange={handleInputChange}
          className={styles.textarea}
          rows={6}
        />
      </FormGroup>
    </Form>
  );
};

export default Nikah;
