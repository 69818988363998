import React from "react";
import styles from "./Form.module.css";

const Form = ({
  onSubmit,
  children,
  title,
  submitButtonText,
  loading,
  error,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(e);
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      {title && <h2 className={styles.formTitle}>{title}</h2>}
      {error && <p className={styles.errorMessage}>{error}</p>}
      {children}
      <button type="submit" className={styles.submitButton} disabled={loading}>
        {loading ? "Loading..." : submitButtonText || "Submit"}
      </button>
    </form>
  );
};

export const FormGroup = ({ children }) => (
  <div className={styles.formGroup}>{children}</div>
);

export const Label = ({ htmlFor, children }) => (
  <label htmlFor={htmlFor} className={styles.label}>
    {children}
  </label>
);

export const Input = ({ type, id, name, value, onChange, required }) => (
  <input
    type={type}
    id={id}
    name={name}
    value={value}
    onChange={onChange}
    className={styles.input}
    required={required}
  />
);

export const Select = ({ id, name, value, onChange, options, required }) => (
  <select
    id={id}
    name={name}
    value={value}
    onChange={onChange}
    className={styles.select}
    required={required}
  >
    <option value="">Select a time slot</option>
    {options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
);

export const TextArea = ({ id, name, value, onChange, rows }) => (
  <textarea
    id={id}
    name={name}
    value={value}
    onChange={onChange}
    className={styles.textarea}
    rows={rows}
  ></textarea>
);

export default Form;
