import React from "react";
import styles from "./Skeleton.module.css";

const Skeleton = ({ type, count = 1, className = "" }) => {
  const renderSkeleton = () => {
    switch (type) {
      case "text":
        return (
          <div
            className={`${styles.skeleton} ${styles.text} ${className}`}
          ></div>
        );
      case "title":
        return (
          <div
            className={`${styles.skeleton} ${styles.title} ${className}`}
          ></div>
        );
      case "avatar":
        return (
          <div
            className={`${styles.skeleton} ${styles.avatar} ${className}`}
          ></div>
        );
      case "thumbnail":
        return (
          <div
            className={`${styles.skeleton} ${styles.thumbnail} ${className}`}
          ></div>
        );
      case "custom":
        return <div className={`${styles.skeleton} ${className}`}></div>;
      default:
        return null;
    }
  };

  return (
    <>
      {[...Array(count)].map((_, index) => (
        <React.Fragment key={index}>{renderSkeleton()}</React.Fragment>
      ))}
    </>
  );
};

export default Skeleton;
