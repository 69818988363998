import React from "react";
import styles from "./Events.module.css";
import Card from "components/ui/card/Card";
import marriageIcon from "assets/services/marriage_icon.png";
import graveyardIcon from "assets/services/graveyard_icon.png";
import quranIcon from "assets/services/quran_icon.png";

const Events = () => {
  const upcomingEvents = [
    {
      id: 1,
      title: "Nikah Ceremony",
      date: "Apr 15, 2024",
      location: "Masjid Al-Noor",
      time: "9:00 AM - 11:00 AM",
      icon: marriageIcon,
    },
    {
      id: 2,
      title: "Janazah Prayer",
      date: "Apr 16, 2024",
      location: "Islamic Center",
      time: "2:00 PM - 3:00 PM",
      icon: graveyardIcon,
    },
    {
      id: 3,
      title: "Quran Recitation",
      date: "Apr 20, 2024",
      location: "Community Hall",
      time: "7:00 PM - 9:00 PM",
      icon: quranIcon,
    },
  ];

  return (
    <div className={styles.container}>
      <p className={styles.subtitle}>Upcoming Events</p>
      <div className={styles.upcomingEvents}>
        {upcomingEvents.map((event) => (
          <Card
            key={event.id}
            icon={event.icon}
            className={styles.eventCard}
            iconWrapperClassName={styles.eventIconWrapper}
          >
            <div className={styles.eventInfo}>
              <h3 className={styles.eventTitle}>{event.title}</h3>
              <p className={styles.eventDate}>{event.date}</p>
              <p className={styles.eventLocation}>{event.location}</p>
              <p className={styles.eventTime}>{event.time}</p>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Events;
