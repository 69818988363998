import React from "react";
import { Route } from "react-router-dom";
import Services from "./Services";
import Marriage from "./marriage/Marriage";
import Nikah from "./marriage/nikah/Nikah";
import Ceremony from "./marriage/ceremony/Ceremony";
import Janazah from "./janazah/Janazah";
import TahfizulQuran from "./tahfizul-quran/TahfizulQuran";
import Madrasah from "./madrasah/madrasah";
import IslamicCounselling from "./islamic-counselling/IslamicCounselling";
import Library from "./library/Library";
import Health from "./health/Health";
import Language from "./language/Language";
import AskImaam from "./ask-imaam/AskImaam";

export const ServicesRoutes = [
  <Route
    path="/dashboard/attendee/services"
    element={<Services />}
    key="services"
  />,
  <Route
    path="/dashboard/attendee/services/marriage"
    element={<Marriage />}
    key="marriage"
  />,
  <Route
    path="/dashboard/attendee/services/marriage/nikah"
    element={<Nikah />}
    key="nikah"
  />,
  <Route
    path="/dashboard/attendee/services/marriage/ceremony"
    element={<Ceremony />}
    key="ceremony"
  />,
  <Route
    path="/dashboard/attendee/services/janazah"
    element={<Janazah />}
    key="janazah"
  />,
  <Route
    path="/dashboard/attendee/services/tahfizul-quran"
    element={<TahfizulQuran />}
    key="tahfizul-quran"
  />,
  <Route
    path="/dashboard/attendee/services/madrasah"
    element={<Madrasah />}
    key="madrasah"
  />,
  <Route
    path="/dashboard/attendee/services/counselling"
    element={<IslamicCounselling />}
    key="counselling"
  />,
  <Route
    path="/dashboard/attendee/services/library"
    element={<Library />}
    key="library"
  />,
  <Route
    path="/dashboard/attendee/services/health"
    element={<Health />}
    key="health"
  />,
  <Route
    path="/dashboard/attendee/services/language"
    element={<Language />}
    key="language"
  />,
  <Route
    path="/dashboard/attendee/services/ask-imaam"
    element={<AskImaam />}
    key="ask-imaam"
  />,
];
