import React from "react";
import styles from "./Health.module.css";

const Health = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Health Services</h1>
      <p className={styles.description}>
        Our Health Services aim to provide support and guidance for the physical
        and mental well-being of our community.
      </p>
      <ul className={styles.serviceList}>
        <li>Health awareness programs</li>
        <li>Fitness classes</li>
        <li>Nutrition counseling</li>
        <li>Mental health support</li>
        <li>First aid training</li>
      </ul>
      <p className={styles.contact}>
        For more information about our Health services, please contact us at
        health@ummapay.com
      </p>
    </div>
  );
};

export default Health;
