import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navigation from "../navigation/Navigation";
import MobileHeader from "../navigation/mobile-header/MobileHeader";
import BackButton from "components/ui/back-button/BackButton";
import styles from "./Layout.module.css";
import { useScrollToTop } from "hooks/useScrollToTop";

const Layout = () => {
  useScrollToTop();
  const location = useLocation();

  const mainMenuPaths = [
    "/dashboard/attendee",
    "/dashboard/attendee/services",
    "/dashboard/attendee/news",
    "/dashboard/attendee/events",
    "/dashboard/attendee/subscription",
  ];

  const showBackButton = !mainMenuPaths.includes(location.pathname);

  return (
    <div className={styles.layout}>
      {showBackButton && <BackButton />}
      <MobileHeader />
      <div className={styles.content}>
        <Navigation />
      </div>
      <div className={styles.pageWrapper}>
        <main className={styles.content}>
          <Outlet />
        </main>
      </div>
      <div className={styles.bottomNavPlaceholder}></div>
    </div>
  );
};

export default Layout;
