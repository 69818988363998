import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { navigateTo } from "store/nikahSlice";
import { FaRing } from "react-icons/fa";
import { GiPartyPopper } from "react-icons/gi";
import styles from "./Marriage.module.css";

const Marriage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigation = (service) => {
    dispatch(navigateTo(service));
    navigate(`/dashboard/attendee/services/marriage/${service}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <button
          className={styles.serviceButton}
          onClick={() => handleNavigation("nikah")}
        >
          <FaRing className={styles.buttonIcon} />
          <span>Nikah</span>
        </button>
        <button
          className={styles.serviceButton}
          onClick={() => handleNavigation("ceremony")}
        >
          <GiPartyPopper className={styles.buttonIcon} />
          <span>Ceremony</span>
        </button>
      </div>
    </div>
  );
};

export default Marriage;
