import React, { useState, useEffect } from "react";
import { getPrayerTimes } from "services/prayerTimes";
import styles from "./PrayerTimes.module.css";
import fajrImage from "assets/prayer-times/fajr.jpeg";
import sunriseImage from "assets/prayer-times/sunrise.jpeg";
import dhuhrImage from "assets/prayer-times/dhur.jpeg";
import asrImage from "assets/prayer-times/asr.jpeg";
import maghribImage from "assets/prayer-times/maghrib.jpeg";
import ishaImage from "assets/prayer-times/isha.jpeg";

const PrayerTimes = () => {
  const [prayerData, setPrayerData] = useState(null);
  const [activePrayer, setActivePrayer] = useState("");
  const [nextPrayer, setNextPrayer] = useState("");
  const [timeUntilNextPrayer, setTimeUntilNextPrayer] = useState("");

  useEffect(() => {
    const fetchPrayerTimes = async () => {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const times = await getPrayerTimes(year, month, "London", "UK", 2);
      const todayData = times[day - 1];
      setPrayerData(todayData);
    };

    fetchPrayerTimes();
  }, []);

  useEffect(() => {
    const updatePrayerStatus = () => {
      if (!prayerData) return;

      const now = new Date();
      const currentTime = now.getHours() * 60 + now.getMinutes();

      const prayers = [
        { name: "Fajr", time: prayerData.timings.Fajr },
        { name: "Sunrise", time: prayerData.timings.Sunrise },
        { name: "Dhuhr", time: prayerData.timings.Dhuhr },
        { name: "Asr", time: prayerData.timings.Asr },
        { name: "Maghrib", time: prayerData.timings.Maghrib },
        { name: "Isha", time: prayerData.timings.Isha },
      ];

      let activeIndex = -1;
      for (let i = prayers.length - 1; i >= 0; i--) {
        const prayerTime = prayers[i].time.split(":");
        const prayerMinutes =
          parseInt(prayerTime[0]) * 60 + parseInt(prayerTime[1]);

        if (currentTime >= prayerMinutes) {
          setActivePrayer(prayers[i].name);
          activeIndex = i;
          break;
        }
      }

      const nextIndex = (activeIndex + 1) % prayers.length;
      setNextPrayer(prayers[nextIndex].name);

      let nextPrayerTime = prayers[nextIndex].time.split(":");
      let nextPrayerMinutes =
        parseInt(nextPrayerTime[0]) * 60 + parseInt(nextPrayerTime[1]);

      // If next prayer is Fajr and it's after Isha, add 24 hours
      if (nextIndex === 0 && activeIndex === prayers.length - 1) {
        nextPrayerMinutes += 24 * 60;
      }

      let minutesUntilNextPrayer = nextPrayerMinutes - currentTime;
      if (minutesUntilNextPrayer < 0) {
        minutesUntilNextPrayer += 24 * 60; // Add 24 hours if negative
      }

      const hoursUntilNextPrayer = Math.floor(minutesUntilNextPrayer / 60);
      const remainingMinutes = minutesUntilNextPrayer % 60;

      // Format the time string
      let timeString = "";
      if (hoursUntilNextPrayer > 0) {
        timeString += `${hoursUntilNextPrayer}h `;
      }
      timeString += `${remainingMinutes}m`;

      setTimeUntilNextPrayer(timeString);
    };

    updatePrayerStatus();
    const interval = setInterval(updatePrayerStatus, 60000);

    return () => clearInterval(interval);
  }, [prayerData]);

  if (!prayerData) {
    return <div>Loading prayer times...</div>;
  }

  const prayersList = [
    { name: "Fajr", time: prayerData.timings.Fajr, image: fajrImage },
    {
      name: "Sunrise",
      time: prayerData.timings.Sunrise,
      image: sunriseImage,
    },
    { name: "Dhuhr", time: prayerData.timings.Dhuhr, image: dhuhrImage },
    { name: "Asr", time: prayerData.timings.Asr, image: asrImage },
    {
      name: "Maghrib",
      time: prayerData.timings.Maghrib,
      image: maghribImage,
    },
    { name: "Isha", time: prayerData.timings.Isha, image: ishaImage },
  ];

  const islamicDate = `${prayerData.date.hijri.day} ${prayerData.date.hijri.month.en} ${prayerData.date.hijri.year} AH`;
  const gregorianDate = `${prayerData.date.gregorian.day} ${prayerData.date.gregorian.month.en} ${prayerData.date.gregorian.year}`;

  return (
    <div className={styles.prayerTimesContainer}>
      <div className={styles.header}>
        <div className={styles.dateInfo}>
          <div className={styles.islamicDate}>{islamicDate}</div>
          <div className={styles.gregorianDate}>{gregorianDate}</div>
        </div>
        <div className={styles.nextPrayerInfo}>
          <div className={styles.nextPrayer}>Next: {nextPrayer}</div>
          <div className={styles.timeUntilNextPrayer}>
            In: {timeUntilNextPrayer}
          </div>
        </div>
      </div>
      <div className={styles.prayerTimesGrid}>
        {prayersList.map((prayer) => (
          <div
            key={prayer.name}
            className={`${styles.prayerTime} ${activePrayer === prayer.name ? styles.activePrayer : ""}`}
          >
            <div className={styles.imageContainer}>
              <img
                src={prayer.image}
                alt={prayer.name}
                className={styles.prayerImage}
              />
            </div>
            <span className={styles.name}>{prayer.name}</span>
            <span className={styles.time}>{prayer.time.slice(0, 5)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrayerTimes;
