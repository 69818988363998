import React, { useState, useRef, useEffect } from "react";
import styles from "./AskImaam.module.css";
import chatIllustration from "assets/chat_background_image.png";

const AskImaam = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const messageContainerRef = useRef(null);

  const sampleQuestions = [
    "Prayer conditions?",
    "How to perform Ghusl?",
    "Missed prayers ruling",
    "Explain Zakat",
    "Ramadan fasting etiquettes",
    "Dealing with waswas in prayer",
  ];

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (newMessage.trim()) {
      const userMessage = {
        id: messages.length + 1,
        text: newMessage,
        sender: "user",
        timestamp: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
      };
      setMessages([...messages, userMessage]);
      setNewMessage("");
      setSelectedQuestion(null);

      // Simulate Imaam response (replace with actual API call in production)
      setTimeout(() => {
        const imaamResponse = {
          id: messages.length + 2,
          text: "Thank you for your question. I'll do my best to provide a helpful answer.",
          sender: "imaam",
          timestamp: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
        };
        setMessages((prevMessages) => [...prevMessages, imaamResponse]);
      }, 1000);
    }
  };

  const handleQuestionClick = (question) => {
    setSelectedQuestion(question);
    setNewMessage(question);
  };

  return (
    <div className={styles.container}>
      <div className={styles.sampleQuestionsContainer}>
        <div className={styles.sampleQuestions}>
          {sampleQuestions.map((question, index) => (
            <button
              key={index}
              className={`${styles.questionButton} ${selectedQuestion === question ? styles.selected : ""}`}
              onClick={() => handleQuestionClick(question)}
            >
              {question}
            </button>
          ))}
        </div>
      </div>
      <div className={styles.chatContainer}>
        <div className={styles.messageContainer} ref={messageContainerRef}>
          {messages.length === 0 ? (
            <div className={styles.illustrationContainer}>
              <img
                src={chatIllustration}
                alt="Chat Illustration"
                className={styles.chatIllustration}
              />
            </div>
          ) : (
            messages.map((message) => (
              <div
                key={message.id}
                className={`${styles.message} ${styles[message.sender]}`}
              >
                <div className={styles.avatarContainer}>
                  <div className={styles.avatar}></div>
                </div>
                <div className={styles.messageContent}>
                  <p className={styles.messageText}>{message.text}</p>
                  <span className={styles.timestamp}>{message.timestamp}</span>
                </div>
              </div>
            ))
          )}
        </div>
        <form onSubmit={handleSendMessage} className={styles.inputContainer}>
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your question or select from above..."
            className={styles.input}
          />
          <button type="submit" className={styles.sendButton}>
            ➤
          </button>
        </form>
      </div>
    </div>
  );
};

export default AskImaam;
