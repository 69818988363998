import React from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../ui/card/Card";
import styles from "./ServiceItem.module.css";

const ServiceItem = ({ service }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/dashboard/attendee/services/${service.path}`);
  };

  return (
    <Card
      icon={service.icon}
      title={service.name}
      onClick={handleClick}
      className={styles.serviceItem}
      iconWrapperClassName={styles.serviceIconWrapper}
      titleClassName={styles.serviceTitle}
    />
  );
};

export default ServiceItem;
