import React from "react";
import QiblaCompass from "../../../../components/attendee/qibla-compass/QiblaCompass";

const Qibla = () => {
  return (
    <div>
      <QiblaCompass />
    </div>
  );
};

export default Qibla;
