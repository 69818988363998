import { configureStore } from "@reduxjs/toolkit";
import nikahReducer from "./nikahSlice";
import authReducer from "./authSlice";

export const store = configureStore({
  reducer: {
    nikah: nikahReducer,
    auth: authReducer,
  },
});

store.subscribe(() => {
  localStorage.setItem("authState", JSON.stringify(store.getState().auth));
});
