import React, { useState, useEffect } from "react";
import Header from "./header/Header";
import Body from "./body/Body";
import Footer from "./footer/Footer";
import backgroundLandscape from "assets/landing_background_landscape.png";
import backgroundPortrait from "assets/landing_background_portrait.png";
import styles from "./Landing.module.css";

function Landing() {
  const [backgroundImage, setBackgroundImage] = useState(backgroundLandscape);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 640) {
        setBackgroundImage(backgroundPortrait);
      } else {
        setBackgroundImage(backgroundLandscape);
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div className={styles.main} style={backgroundStyle}>
      <Header />
      <Body />
      <Footer />
    </div>
  );
}

export default Landing;
