import React from "react";

const Madrasah = () => {
  return (
    <div>
      <h1>Our Madrasah</h1>
      <p>
        Welcome to our Madrasah page. Here you can find information about our
        Islamic education programs.
      </p>
      {/* Add more content specific to the Madrasah */}
    </div>
  );
};

export default Madrasah;
