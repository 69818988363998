import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PublicRoutes } from "./routes/PublicRoutes";
import { AttendeeRoutes } from "./routes/AttendeeRoutes";
import ProtectedRoute from "./routes/ProtectedRoute";
import styles from "./App.module.css";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const storedAuthState = localStorage.getItem("authState");
    if (storedAuthState) {
      dispatch({
        type: "auth/initialize",
        payload: JSON.parse(storedAuthState),
      });
    }
  }, [dispatch]);

  return (
    <Router>
      <div className={styles.appContainer}>
        <Routes>
          {/* Public routes */}
          {PublicRoutes.map((route, index) => (
            <Route
              key={`public-${index}`}
              path={route.path}
              element={route.element}
            />
          ))}
          {/* Protected routes */}
          <Route element={<ProtectedRoute />}>
            {AttendeeRoutes.map((route, index) => (
              <Route
                key={`attendee-${index}`}
                path={route.props.path}
                element={route.props.element}
              >
                {route.props.children}
              </Route>
            ))}
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
