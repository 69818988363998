import React from "react";
import styles from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <h1>Privacy Policy</h1>

      <section>
        <h2>Introduction</h2>
        <p>
          Welcome to UmmaPay. We are committed to protecting your privacy and
          ensuring that your personal information is handled in a safe and
          responsible manner. This Privacy Policy outlines how we collect, use,
          and safeguard your information when you use our website and services.
        </p>
      </section>

      <section>
        <h2>Information We Collect</h2>
        <p>We may collect the following types of information:</p>
        <ul>
          <li>
            <strong>Personal Information:</strong> Name, email address, phone
            number, mailing address, and other contact details.
          </li>
          <li>
            <strong>Financial Information:</strong> Payment details, donation
            amounts, zakat contributions.
          </li>
          <li>
            <strong>Service Bookings:</strong> Details related to hall services,
            nikah services, and other bookings.
          </li>
          <li>
            <strong>Content Uploads:</strong> Videos, lectures, news articles,
            and social media content you upload.
          </li>
          <li>
            <strong>Usage Data:</strong> Information on how you navigate and
            interact with our website.
          </li>
        </ul>
      </section>

      <section>
        <h2>How We Use Your Information</h2>
        <p>We use the collected information for the following purposes:</p>
        <ul>
          <li>Processing payments, donations, and zakat contributions.</li>
          <li>Managing and facilitating service bookings.</li>
          <li>
            Uploading and displaying your content such as videos and lectures.
          </li>
          <li>Improving our website and services based on usage data.</li>
          <li>
            Communicating with you about updates, news, and important
            information.
          </li>
        </ul>
      </section>

      <section>
        <h2>How We Share Your Information</h2>
        <p>
          We do not sell, trade, or otherwise transfer your personal information
          to outside parties except in the following circumstances:
        </p>
        <ul>
          <li>
            <strong>Service Providers:</strong> Trusted third-party vendors who
            assist us in operating our website, conducting our business, or
            servicing you.
          </li>
          <li>
            <strong>Legal Requirements:</strong> When required by law,
            regulation, or legal process.
          </li>
          <li>
            <strong>Protection of Rights:</strong> To protect the rights,
            property, and safety of UmmaPay, our users, or others.
          </li>
        </ul>
      </section>

      <section>
        <h2>Security of Your Information</h2>
        <p>
          We implement a variety of security measures to maintain the safety of
          your personal information. However, no method of transmission over the
          internet or electronic storage is 100% secure, and we cannot guarantee
          absolute security.
        </p>
      </section>

      <section>
        <h2>Your Rights</h2>
        <p>
          Depending on your location, you may have the following rights
          regarding your personal information:
        </p>
        <ul>
          <li>Accessing your personal data.</li>
          <li>Requesting correction of inaccurate information.</li>
          <li>Requesting deletion of your personal data.</li>
          <li>
            Objecting to or restricting certain data processing activities.
          </li>
        </ul>
        <p>
          To exercise these rights, please contact us using the information
          provided below.
        </p>
      </section>

      <section>
        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. Any changes will
          be posted on this page, and the "Last Updated" date will be revised
          accordingly. We encourage you to review this Privacy Policy
          periodically to stay informed about how we are protecting your
          information.
        </p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at:
        </p>
        <ul>
          <li>
            <strong>Email:</strong> admin@ummapay.co.uk
          </li>
          <li>
            <strong>Phone:</strong> +44 74 5502 5053
          </li>
        </ul>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
