import React from "react";
import Landing from "pages/landing/Landing";
import Auth from "pages/auth/Auth";
import PrivacyPolicy from "pages/policies/privacy-policy/PrivacyPolicy";
import TermsOfService from "pages/policies/terms-of-service/TermsOfService";

export const PublicRoutes = [
  { path: "/", element: <Landing /> },
  { path: "/auth", element: <Auth /> },
  { path: "/privacy-policy", element: <PrivacyPolicy /> },
  { path: "/terms-of-service", element: <TermsOfService /> },
];
