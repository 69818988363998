import React, { useState, useEffect } from "react";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import styles from "./DatePicker.module.css";

const DatePicker = ({ selectedDate, onChange }) => {
  const [currentDate, setCurrentDate] = useState(selectedDate || new Date());

  useEffect(() => {
    if (selectedDate) {
      setCurrentDate(new Date(selectedDate));
    }
  }, [selectedDate]);

  const daysInMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0,
  ).getDate();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1,
  ).getDay();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handlePrevMonth = (e) => {
    e.preventDefault();
    setCurrentDate(
      (prevDate) =>
        new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1),
    );
  };

  const handleNextMonth = (e) => {
    e.preventDefault();
    setCurrentDate(
      (prevDate) =>
        new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1),
    );
  };

  const handleDateClick = (e, day) => {
    e.preventDefault();
    const selectedDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day,
    );
    onChange(selectedDate);
  };

  const renderCalendar = () => {
    const calendar = [];
    let day = 1;

    for (let i = 0; i < 6; i++) {
      const week = [];
      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < firstDayOfMonth) {
          week.push(<td key={`empty-${j}`} className={styles.emptyCell}></td>);
        } else if (day > daysInMonth) {
          break;
        } else {
          const currentDay = day; // Capture the current value of day
          const isSelected =
            selectedDate &&
            selectedDate.getDate() === currentDay &&
            selectedDate.getMonth() === currentDate.getMonth() &&
            selectedDate.getFullYear() === currentDate.getFullYear();

          week.push(
            <td
              key={currentDay}
              onClick={(e) => handleDateClick(e, currentDay)}
            >
              <div
                className={`${styles.dateCell} ${isSelected ? styles.selected : ""}`}
              >
                {currentDay}
              </div>
            </td>,
          );
          day++;
        }
      }
      calendar.push(<tr key={i}>{week}</tr>);
      if (day > daysInMonth) break;
    }

    return calendar;
  };

  return (
    <div className={styles.datePicker}>
      <div className={styles.header}>
        <button onClick={handlePrevMonth} className={styles.navButton}>
          <IoChevronBack />
        </button>
        <span className={styles.monthYear}>
          {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
        </span>
        <button onClick={handleNextMonth} className={styles.navButton}>
          <IoChevronForward />
        </button>
      </div>
      <table className={styles.calendar}>
        <thead>
          <tr>
            <th>Sun</th>
            <th>Mon</th>
            <th>Tue</th>
            <th>Wed</th>
            <th>Thu</th>
            <th>Fri</th>
            <th>Sat</th>
          </tr>
        </thead>
        <tbody>{renderCalendar()}</tbody>
      </table>
    </div>
  );
};

export default DatePicker;
