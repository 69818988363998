import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Home.module.css";
import ServiceItem from "components/attendee/service-item/ServiceItem";
import PrayerTimes from "components/attendee/prayer-times/PrayerTimes";
import AskImaam from "pages/dashboard/attendee/services/ask-imaam/AskImaam";
import marriageIcon from "assets/services/marriage_icon.png";
import graveyardIcon from "assets/services/graveyard_icon.png";
import quranIcon from "assets/services/quran_icon.png";
import madrasaIcon from "assets/services/madrasa_icon.png";
import counsellingIcon from "assets/services/islamic_counselling_icon.png";
import libraryIcon from "assets/services/library_icon.png";
import healthIcon from "assets/services/health_icon.png";
import languageIcon from "assets/services/language_icon.png";
import askImaamIcon from "assets/services/ask_imaam_icon.png";
import { IoChevronForward } from "react-icons/io5";

const AttendeeHome = () => {
  const navigate = useNavigate();

  const services = [
    { name: "Marriage", icon: marriageIcon, path: "marriage" },
    { name: "Janazah", icon: graveyardIcon, path: "janazah" },
    { name: "Qur'an", icon: quranIcon, path: "tahfizul-quran" },
    { name: "Madrasah", icon: madrasaIcon, path: "madrasah" },
    { name: "Counselling", icon: counsellingIcon, path: "counselling" },
    { name: "Library", icon: libraryIcon, path: "library" },
    { name: "Health", icon: healthIcon, path: "health" },
    { name: "Language", icon: languageIcon, path: "language" },
    { name: "Ask Imaam", icon: askImaamIcon, path: "ask-imaam" },
  ];
  const handleViewAllServices = () => {
    navigate("/dashboard/attendee/services");
  };
  const handleQibla = () => {
    navigate("/dashboard/attendee/qibla");
  };
  return (
    <div className={styles.container}>
      <section>
        <div className={styles.sectionHeader}>
          <h2 className={styles.sectionTitle}>Services</h2>
          <span className={styles.seeAll} onClick={handleViewAllServices}>
            See all <IoChevronForward className={styles.arrowIcon} />
          </span>
        </div>
        <div className={styles.servicesContainer}>
          <div className={styles.servicesGrid}>
            {services.map((service, index) => (
              <ServiceItem key={index} service={service} />
            ))}
          </div>
        </div>
      </section>

      <hr className={styles.sectionDivider} />

      <section>
        <div className={styles.sectionHeader}>
          <h2 className={styles.sectionTitle}>Prayer Times</h2>
          <span className={styles.qibla} onClick={handleQibla}>
            Qibla <IoChevronForward className={styles.arrowIcon} />
          </span>
        </div>
        <div className={styles.prayerTimesContainer}>
          <PrayerTimes />
        </div>
      </section>

      <hr className={styles.sectionDivider} />

      <section>
        <div className={styles.sectionHeader}>
          <h2 className={styles.sectionTitle}>Ask your Imaam</h2>
          <span className={styles.seeAll} onClick={handleViewAllServices}>
            Call Imaam <IoChevronForward className={styles.arrowIcon} />
          </span>
        </div>
        <div className={styles.askImaamContainer}>
          <AskImaam />
        </div>
      </section>
    </div>
  );
};

export default AttendeeHome;
