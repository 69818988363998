import React from "react";
import styles from "./Subscription.module.css";
import {
  IoMoonOutline,
  IoSettingsOutline,
  IoChevronForward,
} from "react-icons/io5";

const Subscription = () => {
  return (
    <div className={styles.container}>
      <div className={styles.currentPlan}>
        <IoSettingsOutline className={styles.icon} />
        <p>You are on the ? plan</p>
      </div>

      <p className={styles.gratitude}>
        On behalf of the mosque, we would like to extend our heartfelt gratitude
        for your generous assistance.
      </p>

      <p className={styles.quote}>
        As our beloved Prophet Muhammad ﷺ said, "Charity does not in any way
        decrease wealth."
      </p>

      <p className={styles.blessing}>
        May Allah reward your contributions abundantly!
      </p>

      <div className={styles.planList}>
        <div className={styles.planItem}>
          <IoMoonOutline className={styles.planIcon} />
          <span className={styles.planName}>Silver</span>
          <span className={styles.planPrice}>£50.00</span>
          <IoChevronForward className={styles.arrowIcon} />
        </div>

        <div className={styles.planItem}>
          <IoMoonOutline className={styles.planIcon} />
          <span className={styles.planName}>Gold</span>
          <span className={styles.planPrice}>£100.00</span>
          <IoChevronForward className={styles.arrowIcon} />
        </div>

        <div className={styles.planItem}>
          <IoMoonOutline className={styles.planIcon} />
          <span className={styles.planName}>Custom</span>
          <IoChevronForward className={styles.arrowIcon} />
        </div>
      </div>

      <button className={styles.manageButton}>
        <IoSettingsOutline className={styles.buttonIcon} />
        Manage Subscription
      </button>
    </div>
  );
};

export default Subscription;
