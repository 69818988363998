import React from "react";
import styles from "./Library.module.css";

const Library = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Library Services</h1>
      <p className={styles.description}>
        Welcome to our Islamic Library. Here you can find a wide range of
        Islamic literature and resources.
      </p>
      <ul className={styles.serviceList}>
        <li>Book borrowing</li>
        <li>Digital resources</li>
        <li>Study areas</li>
        <li>Research assistance</li>
        <li>Islamic literature workshops</li>
      </ul>
      <p className={styles.contact}>
        For more information about our Library services, please contact us at
        library@ummapay.com
      </p>
    </div>
  );
};

export default Library;
