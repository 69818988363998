import React from "react";
import styles from "./Language.module.css";

const Language = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Language Services</h1>
      <p className={styles.description}>
        Our Language Services offer various programs to help you learn and
        improve your language skills.
      </p>
      <ul className={styles.serviceList}>
        <li>Arabic language courses</li>
        <li>Quranic Arabic classes</li>
        <li>English as a Second Language (ESL)</li>
        <li>Translation services</li>
        <li>Language exchange programs</li>
      </ul>
      <p className={styles.contact}>
        For more information about our Language services, please contact us at
        language@ummapay.com
      </p>
    </div>
  );
};

export default Language;
