import React from "react";
import { useLocation } from "react-router-dom";
import SettingsButton from "components/ui/settings-button/SettingsButton";
import styles from "./MobileHeader.module.css";

const MobileHeader = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];

  let currentPage = lastSegment === "attendee" ? "Home" : lastSegment;
  currentPage = currentPage.charAt(0).toUpperCase() + currentPage.slice(1);

  return (
    <div className={styles.mobileHeader}>
      {currentPage}
      <SettingsButton />
    </div>
  );
};

export default MobileHeader;
