import React from "react";
import Skeleton from "components/ui/skeleton/Skeleton";
import styles from "./NewsSkeletonLoader.module.css";

const NewsSkeletonLoader = () => {
  return (
    <div className={styles.skeletonContainer}>
      <div className={styles.categoryFilter}>
        <Skeleton type="text" count={3} className={styles.categoryButton} />
      </div>
      <div className={styles.featuredNews}>
        <Skeleton type="thumbnail" className={styles.featuredImage} />
        <div className={styles.featuredContent}>
          <Skeleton type="title" />
          <Skeleton type="text" count={3} />
          <Skeleton type="text" className={styles.creator} />
        </div>
      </div>
      <div className={styles.liveUpdates}>
        <Skeleton type="title" className={styles.liveUpdatesTitle} />
        {[1, 2, 3].map((i) => (
          <div key={i} className={styles.updateItem}>
            <Skeleton type="text" className={styles.updateTimestamp} />
            <Skeleton type="text" className={styles.updateText} />
            <Skeleton type="text" className={styles.updateCreator} />
          </div>
        ))}
      </div>
      {[1, 2, 3].map((i) => (
        <div key={i} className={styles.newsItem}>
          <Skeleton type="thumbnail" className={styles.newsImage} />
          <div className={styles.newsContent}>
            <Skeleton type="title" className={styles.newsTitle} />
            <Skeleton type="text" className={styles.newsTimestamp} />
            <Skeleton
              type="text"
              count={2}
              className={styles.newsDescription}
            />
            <Skeleton type="text" className={styles.newsAuthor} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default NewsSkeletonLoader;
