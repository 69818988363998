import React, { useState } from "react";
import { HiMenu, HiX } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import styles from "./Header.module.css";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSignUp = () => {
    navigate("/signup");
  };
  const handleDashboard = () => {
    navigate("/dashboard/attendee");
  };

  return (
    <header className={styles.header}>
      <div className={styles.leftSection}>
        <div className={styles.logoContainer}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            width="32"
            height="32"
            className={styles.logo}
          >
            <circle cx="16" cy="16" r="16" fill="#000" />
            <path d="M26,14 A8,8 0 1,0 30,24 A6,6 0 1,1 26,14" fill="#fff" />
          </svg>
          <span className={styles.logoText}>UmmaPay</span>
        </div>
        <div className={styles.verticalLine}></div>
      </div>
      <div className={styles.buttonContainer}>
        <button
          className={`${styles.button} ${styles.loginButton}`}
          onClick={handleLogin}
        >
          Login
        </button>
        <button
          className={`${styles.button} ${styles.signUpButton}`}
          onClick={handleSignUp}
        >
          Sign Up
        </button>
      </div>
      <div className={styles.burgerMenuContainer}>
        <button className={styles.burgerButton} onClick={toggleMenu}>
          {isMenuOpen ? <HiX size={24} /> : <HiMenu size={24} />}
        </button>
      </div>
      <div className={`${styles.mobileMenu} ${isMenuOpen ? styles.open : ""}`}>
        <button
          className={`${styles.button} ${styles.loginButton}`}
          onClick={handleLogin}
        >
          Login
        </button>
        <button
          className={`${styles.button} ${styles.signUpButton}`}
          onClick={handleSignUp}
        >
          Sign Up
        </button>
        <button
          className={`${styles.button} ${styles.signUpButton}`}
          onClick={handleDashboard}
        >
          Dashboard
        </button>
      </div>
      <div className={styles.bottomLine}></div>
    </header>
  );
};

export default Header;
