import React, { useState, useEffect, useCallback } from "react";
import styles from "./QiblaCompass.module.css";
import qiblaIcon from "../../../assets/qibla_icon.png";
import Button from "../../ui/button/Button";

const QiblaCompass = () => {
  const [qiblaDirection, setQiblaDirection] = useState(null);
  const [deviceDirection, setDeviceDirection] = useState(0);

  const calculateQiblaDirection = (latitude, longitude) => {
    const kaabaLat = 21.4225 * (Math.PI / 180);
    const kaabaLong = 39.8262 * (Math.PI / 180);
    const lat = latitude * (Math.PI / 180);
    const long = longitude * (Math.PI / 180);

    const y = Math.sin(kaabaLong - long);
    const x =
      Math.cos(lat) * Math.tan(kaabaLat) -
      Math.sin(lat) * Math.cos(kaabaLong - long);
    const qiblaAngle = Math.atan2(y, x);

    return ((qiblaAngle * 180) / Math.PI + 360) % 360;
  };

  const updateQiblaDirection = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const qiblaAngle = calculateQiblaDirection(latitude, longitude);
        setQiblaDirection(qiblaAngle);
      },
      (error) => {
        console.error("Error getting geolocation:", error);
      },
    );
  }, []);

  useEffect(() => {
    const handleOrientation = (event) => {
      let angle;
      if (event.webkitCompassHeading) {
        // For iOS devices
        angle = event.webkitCompassHeading;
      } else if (event.alpha) {
        // For Android devices
        angle = 360 - event.alpha;
      }
      if (angle !== undefined) {
        setDeviceDirection(angle);
      }
    };

    updateQiblaDirection();

    if (window.DeviceOrientationEvent) {
      if (typeof DeviceOrientationEvent.requestPermission === "function") {
        // For iOS 13+ devices
        DeviceOrientationEvent.requestPermission()
          .then((response) => {
            if (response === "granted") {
              window.addEventListener(
                "deviceorientation",
                handleOrientation,
                true,
              );
            }
          })
          .catch(console.error);
      } else {
        // For non-iOS devices or iOS < 13
        window.addEventListener("deviceorientation", handleOrientation, true);
      }
    }

    return () => {
      window.removeEventListener("deviceorientation", handleOrientation, true);
    };
  }, [updateQiblaDirection]);

  const compassStyle = {
    transform: `rotate(${deviceDirection}deg)`,
  };

  const arrowStyle = {
    transform: `rotate(${qiblaDirection - deviceDirection}deg)`,
  };

  return (
    <div className={styles.qiblaCompassContainer}>
      <div className={styles.compass} style={compassStyle}>
        <div className={styles.compassInner}>
          <div className={styles.mainArrow} style={arrowStyle}>
            <div className={styles.qiblaIcon}>
              <img src={qiblaIcon} alt="Qibla" />
            </div>
            <div className={styles.arrowUp}></div>
            <div className={styles.arrowDown}></div>
          </div>
        </div>
      </div>
      <div className={styles.info}>
        <p className={styles.directionText}>
          {qiblaDirection !== null
            ? `Qibla Direction: ${Math.round(qiblaDirection)}°`
            : "Calculating Qibla Direction..."}
        </p>
        <p className={styles.directionText}>
          {deviceDirection !== null
            ? `Device Direction: ${Math.round(deviceDirection)}°`
            : "Calculating Device Direction..."}
        </p>
        <Button
          onClick={updateQiblaDirection}
          className={styles.recalculateButton}
        >
          Recalculate Qibla
        </Button>
      </div>
    </div>
  );
};

export default QiblaCompass;
