import React from "react";
import { HiOutlineCog6Tooth } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import styles from "./SettingsButton.module.css";

const SettingsButton = () => {
  const navigate = useNavigate();

  const handleSettingsClick = () => {
    navigate("/dashboard/attendee/settings");
  };

  return (
    <button className={styles.settingsButton} onClick={handleSettingsClick}>
      <HiOutlineCog6Tooth size={24} className={styles.icon} />
    </button>
  );
};

export default SettingsButton;
