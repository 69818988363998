import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signInUp, verifyCode } from "store/authSlice";
import styles from "./Auth.module.css";
import loginImage from "assets/auth_image.png";

const Auth = () => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [step, setStep] = useState("email");
  const [allowPersistentLogin, setAllowPersistentLogin] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, loading, error } = useSelector(
    (state) => state.auth,
  );

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard/attendee");
    }
  }, [isAuthenticated, navigate]);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    const resultAction = await dispatch(
      signInUp({ email, allowPersistentLogin }),
    );
    if (signInUp.fulfilled.match(resultAction)) {
      setStep("verification");
    }
  };

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    const resultAction = await dispatch(verifyCode({ email, code }));
    if (verifyCode.fulfilled.match(resultAction)) {
      navigate("/dashboard/attendee");
    }
  };

  if (isAuthenticated) {
    return null;
  }

  return (
    <div className={styles.authContainer}>
      <div
        className={styles.illustrationSection}
        style={{
          backgroundImage: `url(${loginImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div className={styles.formSection}>
        <h2>
          {step === "email" ? "Enter your email" : "Enter verification code"}
        </h2>
        {error && <p className={styles.errorMessage}>{error}</p>}
        {step === "email" ? (
          <form onSubmit={handleEmailSubmit}>
            <div className={styles.inputGroup}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className={styles.checkboxGroup}>
              <input
                type="checkbox"
                id="allowPersistentLogin"
                checked={allowPersistentLogin}
                onChange={(e) => setAllowPersistentLogin(e.target.checked)}
              />
              <label htmlFor="allowPersistentLogin">
                Allow authentication cookie
              </label>
            </div>
            <button
              type="submit"
              className={styles.submitButton}
              disabled={loading}
            >
              {loading ? "Sending..." : "Send Verification Code"}
            </button>
          </form>
        ) : (
          <form onSubmit={handleVerificationSubmit}>
            <div className={styles.inputGroup}>
              <label htmlFor="verificationCode">Verification Code</label>
              <input
                type="text"
                id="verificationCode"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className={styles.submitButton}
              disabled={loading}
            >
              {loading ? "Verifying..." : "Verify"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Auth;
