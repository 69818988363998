import React from "react";
import styles from "./Ceremony.module.css";

const Ceremony = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Marriage Ceremony Services</h1>
      <p className={styles.description}>
        Welcome to our Marriage Ceremony Services page. We offer comprehensive
        planning and execution for your special day.
      </p>
      <ul className={styles.serviceList}>
        <li>Venue selection and booking</li>
        <li>Catering arrangements</li>
        <li>Decoration services</li>
        <li>Photography and videography</li>
        <li>Wedding invitation design and distribution</li>
        <li>Coordination of the ceremony program</li>
      </ul>
      <p className={styles.contact}>
        For more information or to book our Marriage Ceremony services, please
        contact us at ceremony@ummapay.com
      </p>
    </div>
  );
};

export default Ceremony;
