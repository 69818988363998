import React from "react";
import { Route } from "react-router-dom";
import Layout from "pages/dashboard/attendee/layout/Layout";
import Home from "pages/dashboard/attendee/home/Home";
import Events from "pages/dashboard/attendee/events/Events";
import Subscription from "pages/dashboard/attendee/subscription/Subscription";
import { ServicesRoutes } from "pages/dashboard/attendee/services/ServicesRoutes";
import News from "pages/dashboard/attendee/news/News";
import Settings from "pages/dashboard/attendee/settings/Settings";
import Qibla from "pages/dashboard/attendee/qibla/Qibla";

export const AttendeeRoutes = [
  <Route path="/dashboard/attendee" element={<Layout />} key="layout">
    <Route index element={<Home />} />
    {ServicesRoutes}
    <Route path="news" element={<News />} />
    <Route path="events" element={<Events />} />
    <Route path="subscription" element={<Subscription />} />
    <Route path="settings" element={<Settings />} />
    <Route path="qibla" element={<Qibla />} />
  </Route>,
];
