import React, { useState } from "react";
import styles from "./News.module.css";
import Card from "components/ui/card/Card";
import Feed from "./feed/Feed";
import MosqueInformation from "./mosque-information/MosqueInformation";
import Reminders from "./reminders/Reminders";
import mosqueIcon from "assets/news/mosque_icon.png";
import newsIcon from "assets/news/news_icon.png";
import reminderIcon from "assets/news/reminder_icon.png";

const News = () => {
  const [selectedSection, setSelectedSection] = useState("News");

  const sections = [
    { name: "News", icon: newsIcon },
    { name: "Mosque", icon: mosqueIcon, title: "Mosque Information" },
    { name: "Reminders", icon: reminderIcon },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.sectionSelectorWrapper}>
        <div className={styles.sectionSelector}>
          {sections.map((section) => (
            <Card
              key={section.name}
              icon={section.icon}
              title={section.title || section.name}
              onClick={() => setSelectedSection(section.name)}
              className={`${styles.sectionCard} ${
                selectedSection === section.name ? styles.selected : ""
              }`}
              iconWrapperClassName={styles.sectionIconWrapper}
              titleClassName={styles.sectionTitle}
            />
          ))}
        </div>
      </div>
      <div className={styles.contentSection}>
        {selectedSection === "News" && (
          <Feed selectedSection={selectedSection} />
        )}
        {selectedSection === "Mosque" && <MosqueInformation />}
        {selectedSection === "Reminders" && <Reminders />}
      </div>
    </div>
  );
};

export default News;
