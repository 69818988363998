import React from "react";
import styles from "./TermsOfService.module.css";

const TermsOfService = () => {
  return (
    <div className={styles.container}>
      <h1>Terms of Service</h1>

      <section>
        <h2>1. Introduction</h2>
        <p>
          Welcome to UmmaPay. By accessing or using our website and services,
          you agree to comply with and be bound by these Terms of Service.
          Please read them carefully.
        </p>
      </section>

      <section>
        <h2>2. Acceptance of Terms</h2>
        <p>
          By registering for an account, making payments, donations, or using
          any of our services, you accept these Terms of Service. If you do not
          agree with any part of these terms, you must not use our services.
        </p>
      </section>

      <section>
        <h2>3. User Responsibilities</h2>
        <ul>
          <li>
            <strong>Accurate Information:</strong> You agree to provide accurate
            and complete information when creating an account or making
            transactions.
          </li>
          <li>
            <strong>Account Security:</strong> You are responsible for
            maintaining the confidentiality of your account credentials and for
            all activities that occur under your account.
          </li>
          <li>
            <strong>Compliance:</strong> You agree to use the services only for
            lawful purposes and in accordance with these Terms.
          </li>
        </ul>
      </section>

      <section>
        <h2>4. Payment and Donations</h2>
        <p>
          All payments, donations, and zakat contributions processed through our
          platform are subject to the applicable financial regulations and our
          payment policies. We reserve the right to refuse or cancel
          transactions at our discretion.
        </p>
      </section>

      <section>
        <h2>5. Service Bookings</h2>
        <p>
          You may book hall services, nikah services, and other offerings
          through our platform. All bookings are subject to availability and
          confirmation. We are not responsible for any scheduling conflicts or
          cancellations initiated by third parties.
        </p>
      </section>

      <section>
        <h2>6. Content Uploads</h2>
        <p>
          Users may upload news articles, videos, lectures, and other content to
          the website. By uploading content, you grant us a non-exclusive,
          royalty-free license to use, reproduce, and display the content as
          part of our services. You are responsible for ensuring that your
          content does not infringe on any third-party rights or violate any
          laws.
        </p>
      </section>

      <section>
        <h2>7. Intellectual Property</h2>
        <p>
          All content on the website, including text, graphics, logos, and
          software, is the property of UmmaPay or its licensors and is protected
          by intellectual property laws. You may not reproduce, distribute, or
          create derivative works without our explicit permission.
        </p>
      </section>

      <section>
        <h2>8. Termination</h2>
        <p>
          We reserve the right to terminate or suspend your account and access
          to our services at our sole discretion, without notice, for conduct
          that we believe violates these Terms or is harmful to other users or
          the organization.
        </p>
      </section>

      <section>
        <h2>9. Limitation of Liability</h2>
        <p>
          To the fullest extent permitted by law, UmmaPay shall not be liable
          for any indirect, incidental, special, consequential, or punitive
          damages arising out of your use of the services.
        </p>
      </section>

      <section>
        <h2>10. Indemnification</h2>
        <p>
          You agree to indemnify and hold harmless UmmaPay, its affiliates,
          officers, and employees from any claims, liabilities, damages, and
          expenses arising out of your use of the services or violation of these
          Terms.
        </p>
      </section>

      <section>
        <h2>11. Governing Law</h2>
        <p>
          These Terms shall be governed and construed in accordance with the
          laws of the UK, without regard to its conflict of law provisions.
        </p>
      </section>

      <section>
        <h2>12. Changes to Terms</h2>
        <p>
          We may update our Terms of Service from time to time. Any changes will
          be posted on this page, and the "Last Updated" date will be revised
          accordingly. Continued use of the services after any changes
          constitutes acceptance of the new Terms.
        </p>
      </section>

      <section>
        <h2>13. Contact Us</h2>
        <p>
          If you have any questions about these Terms of Service, please contact
          us at:
        </p>
        <ul>
          <li>
            <strong>Email:</strong> admin@ummapay.co.uk
          </li>
          <li>
            <strong>Phone:</strong> +44 74 5502 5053
          </li>
        </ul>
      </section>
    </div>
  );
};

export default TermsOfService;
